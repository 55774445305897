<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="isShowBtns.indexOf('ContractOrderlist-/api/quotation/add') > -1"
          type="primary"
          @click="GoToBuildQuotation(record, 'add')"
          >新增</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="PackageTemplate"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="qid"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1800px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!-- <span slot="qbcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->

      <span slot="qstate" slot-scope="text">
        <div>{{ text | SigningProgress }}</div>
      </span>
      <span slot="createTime" slot-scope="text">
        <div v-if="text == ''">{{ "-" }}</div>
        <div>{{ $Format(text).slice(0, 10) }}</div>
      </span>
      <span slot="qmoney" slot-scope="text">
        <div>{{ "￥" + text }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          v-if="
            isShowBtns.indexOf('ContractOrderlist-/api/quotation/update') > -1
          "
          @click="GoToBuildQuotation(record, 'edit')"
          >详情</a
        >
        <a-popconfirm
          v-if="
            isShowBtns.indexOf('ContractOrderlist-/api/quotation/delete') > -1
          "
          title="确定删除此条签约订单信息吗?"
          @confirm="() => onDelete(record)"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('ContractOrderlist-/api/quotation/update') > -1
          "
          @click="ShowConversionProgress(record)"
          >转化进度</a
        >
        <a v-if="!record.qwsId" @click="GoToBuildSchedule(record, 'add')"
          >去排期</a
        >
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :visible="visible"
      @close="onClose"
      :width="700"
      :destroyOnClose="destroyOnClose"
    >
      <ConversionProgress
        v-if="ConversionProgress"
        :record="record"
        @onClose="onClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetQuotationList, DeleteQuotation } from "@/api/apiJF/quotation";
import { GetCompanies } from "@/api/device";
import ConversionProgress from "./ConversionProgress.vue";

// 搜索条件
const queryItems = [
  {
    label: "设计师",
    value: "qDesName",
    type: "input",
  },
  // {
  //   label: "报价类型",
  //   value: "qType",
  //   type: "select",
  //   selectOption: "QuotationType",
  // },
  {
    label: "公司名称",
    value: "qBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
  {
    label: "进度状态",
    value: "qState",
    type: "select",
    selectOption: "signingprogress",
  },
  {
    label: "客户姓名",
    value: "qCusName",
    type: "input",
  },
];

const columns = [
  {
    title: "创建时间",
    width: "160px",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    align: "center",
  },
  {
    title: "销售订单编号",
    width: "180px",
    dataIndex: "qsaleNo",
    align: "center",
  },
  {
    title: "签约报价编号",
    width: "180px",
    dataIndex: "qno",
    align: "center",
  },
  {
    title: "项目名称",
    width: "120px",
    dataIndex: "qname",
    // align: "center",
  },
  {
    title: "客户姓名",
    width: "100px",
    dataIndex: "qcusName",
    align: "center",
  },
  // {
  //   title: "公司名称",
  //   // width: "200px",
  //   scopedSlots: { customRender: "qbcId" },
  //   dataIndex: "qbcId",
  //   align: "center",
  // },
  {
    title: "工地地址",
    // width: "100px",
    dataIndex: "qaddress",
    align: "center",
  },
  {
    title: "联系电话",
    width: "100px",
    dataIndex: "qcusTel",
    align: "center",
  },
  {
    title: "进度状态",
    width: "100px",
    dataIndex: "qstate",
    scopedSlots: { customRender: "qstate" },
    align: "center",
  },
  {
    title: "签约金额(元)",
    width: "100px",
    dataIndex: "qcmoney",
    align: "center",
  },
  {
    title: "竣工结算金额(元)",
    width: "120px",
    dataIndex: "qmoney",
    scopedSlots: { customRender: "qmoney" },
    align: "center",
  },
  {
    title: "合同编号",
    width: "100px",
    dataIndex: "qcno",
    align: "center",
  },
  {
    title: "设计图",
    width: "100px",
    // scopedSlots: { customRender: "qcusName" },
    // dataIndex: "qcusName",
    align: "center",
  },
  {
    title: "设计师",
    width: "90px",
    scopedSlots: { customRender: "qdesName" },
    dataIndex: "qdesName",
    align: "center",
  },
  {
    title: "操作",
    width: "200px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    // align: "center",
  },
];

export default {
  name: "ContractOrderlist",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    ConversionProgress,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        QcusName: "",
        qDesName: "",
        qState: "",
        qType: "3",
        qBcIds: "24",
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: {
        // qBcIds: 0,
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      DrawerTitle: "转化进度",
      destroyOnClose: true,
      ConversionProgress: false,
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.qBcIds = this.CompanyListid.toString();
          // this.record.qBcIds = this.CompanyListid.toString();
          this.$refs.table.refresh(true);
          // 获取套餐名称及id

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },

    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("PackageTemplateColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "" && this.queryParam[el] != undefined) {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      return GetQuotationList(params).then((res) => {
        // res.data.pageNo = res.data.page;
        // res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }
      if (this.queryParam.qBcIds == "") {
        this.queryParam.qType = "3";
        this.queryParam.qBcIds = this.CompanyListid.toString();
      }
      // console.log("this.queryParam", this.queryParam);

      this.$refs.table.refresh(true);
    },
    // 删除类别信息
    onDelete(record) {
      console.log("record", record);
      const param = {
        qId: record.qid,
      };
      const orderParam = ["qId"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return DeleteQuotation(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 跳往新建报价页面
    GoToBuildQuotation(record, type) {
      let params = JSON.stringify(record);
      this.$router.push({
        path: "/ContractOrderlistEdit",
        // name: "BuildQuotation",
        query: { record: params, type: type },
      });
    },
    // 跳往新建排期
    GoToBuildSchedule(record, type) {
      console.log("record", record);
      let params = {
        wsBcId: record.qbcId,
        wsQId: record.qid,
      };
      this.$router.push({
        path: "/BuildSchedule",
        query: { record: JSON.stringify(params), type: type },
      });
    },
    // 转化进度
    ShowConversionProgress(record) {
      this.visible = true;
      this.ConversionProgress = true;
      this.record = JSON.stringify(record);
      // this.type = type;
    },
    onClose() {
      this.visible = false;
      this.record = "";
      this.ConversionProgress = false;
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.delName {
  color: red;
  margin: 0 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
.editBtn {
  margin-right: 20px;
}
</style>