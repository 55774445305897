var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('s-search',{ref:"SSearch",attrs:{"queryItems":_vm.queryItems},on:{"fetchData":_vm.fetchUsers}}),_c('div',{staticClass:"table-button table-button-flex"},[_c('div',[(_vm.isShowBtns.indexOf('ContractOrderlist-/api/quotation/add') > -1)?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.GoToBuildQuotation(_vm.record, 'add')}}},[_vm._v("新增")]):_vm._e()],1),_c('div',[_c('a-button',{attrs:{"type":"plain","icon":"redo"},on:{"click":function($event){return _vm.$refs.table.refresh(true)}}},[_vm._v("刷新")]),_c('columns-set',{attrs:{"tableName":"PackageTemplate","columns":_vm.columns},on:{"changeColumns":_vm.changeColumns}})],1)]),_c('s-table',{ref:"table",attrs:{"size":"small","rowKey":"qid","columns":_vm.tableColumns,"data":_vm.loadData,"showPagination":true,"scroll":{ x: '1800px', y: 'calc(82vh - 150px )' },"tableLayout":"fixed"},scopedSlots:_vm._u([{key:"qstate",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s(_vm._f("SigningProgress")(text)))])])}},{key:"createTime",fn:function(text){return _c('span',{},[(text == '')?_c('div',[_vm._v(_vm._s("-"))]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$Format(text).slice(0, 10)))])])}},{key:"qmoney",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s("￥" + text))])])}},{key:"action",fn:function(text, record){return _c('span',{staticClass:"action"},[(
          _vm.isShowBtns.indexOf('ContractOrderlist-/api/quotation/update') > -1
        )?_c('a',{on:{"click":function($event){return _vm.GoToBuildQuotation(record, 'edit')}}},[_vm._v("详情")]):_vm._e(),(
          _vm.isShowBtns.indexOf('ContractOrderlist-/api/quotation/delete') > -1
        )?_c('a-popconfirm',{attrs:{"title":"确定删除此条签约订单信息吗?"},on:{"confirm":() => _vm.onDelete(record)}},[_c('a',{staticClass:"delName"},[_vm._v("删除")])]):_vm._e(),(
          _vm.isShowBtns.indexOf('ContractOrderlist-/api/quotation/update') > -1
        )?_c('a',{staticClass:"editBtn",on:{"click":function($event){return _vm.ShowConversionProgress(record)}}},[_vm._v("转化进度")]):_vm._e(),(!record.qwsId)?_c('a',{on:{"click":function($event){return _vm.GoToBuildSchedule(record, 'add')}}},[_vm._v("去排期")]):_vm._e()],1)}}])}),_c('a-drawer',{attrs:{"title":_vm.DrawerTitle,"placement":"right","visible":_vm.visible,"width":700,"destroyOnClose":_vm.destroyOnClose},on:{"close":_vm.onClose}},[(_vm.ConversionProgress)?_c('ConversionProgress',{attrs:{"record":_vm.record},on:{"onClose":_vm.onClose}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }